#logo,
#logo-caption {
  width: var(--logo-width);

  padding-bottom: 3rem;
}

.issue-card {
  background-color: #ECEBFF;
  padding: 5em 10vw;
  display: flex;
  flex-flow: row nowrap;
  justify-items: center;
  align-items: center;
}

.issue-card > aside {
  flex: 1;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: clamp(0.2rem, 9vw, 2rem);
}

.issue-card > aside > * {
  width: 100%;
}

.issue-card > aside section {
  width: 100%;

  display: grid;
  grid-template: 1fr 1fr 1fr / 6rem 1fr;
}

.issue-card > aside section > * {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.issue-card > aside section > .icon-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-weight: 700;
  font-size: clamp(0.2rem, 9vw, 2rem);
}

.issue-card > aside section > .icon-container * {
  font-size: inherit;
}

.issue-card > aside section > .icon-container:first-of-type > *:first-of-type {
  color: var(--RISDBlue)
}

.issue-card  h2 {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 5px;
  color: #030026;
}

.issue-card p {
  color: #030026;
  font-family: 'Open Sans', sans-serif;
}

.issue-card > figure {
  margin: 0;
  padding: 0;
  flex: 1;
  height: 100%;
}

.issue-card > figure > img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

@media screen and (max-width: 350px) {
  .issue-card {
    text-align: center;
  }

  .issue-card > aside section {
    grid-template: repeat(6, auto) / 1fr;
  }

  .issue-card > aside section > .icon-container {
    margin-top: clamp(0.2rem, 9vw, 2rem);
  }
}

.our-mission {
  box-sizing: border-box;
  width: 100%;
  padding: clamp(0.5rem, 12vw, 5rem) 10vw;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background-color: #030026;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
}

.our-mission > * {
  color: #ECEBFF;
}

.our-mission h2 {
  width: 100%;
  text-align: center;
  color: #ECEBFF;
}

.our-mission p {
  font-weight: lighter;
  text-align: center;
}

.our-solution {
  position: relative;
  box-sizing: border-box;

  background: #EBF1FF;
}

.our-solution > figure {
  width: 100%;

  overflow: hidden;
}

.our-solution > figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.our-solution aside {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 10vw;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.our-solution main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: clamp(0.1rem, 6vw, 1rem);
}

.our-solution main h2 {
  width: 100%;
  margin-top: clamp(0.3rem, 10vw, 3rem);
}

.our-solution main p {
  width: 60%;
  margin-bottom: clamp(0.1rem, 6vw, 1rem);

}

.our-solution ul {
  align-self: center;
  margin-bottom: 2rem;

  display: grid;
  grid-template: repeat(4, 1fr) / 1fr 1fr;
  gap: clamp(0.1rem, 6vw, 1rem) clamp(0.5rem, 12vw, 5rem);

  list-style: none;

  padding: clamp(0.2rem, 9vw, 2rem);
  border-radius: 10px;
  
  background-color: white;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

.our-solution li {
  width: 100%;
  display: grid;
  grid-template-columns: clamp(0.3rem, 10vw, 3rem) 1fr;
  gap: clamp(0.1rem, 6vw, 1rem);
}

.our-solution li > figure {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-solution li > p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.our-solution footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-solution .custom-button {
  padding: clamp(0.1rem, 6vw, 1rem) clamp(0.1rem, 6vw, 1rem);
  border-radius: clamp(0.1rem, 6vw, 1rem);

  color: #fff;
  background-color: #355DF2;

  text-align: center;
  text-decoration: none;  
}

@media screen and (max-width: 800px) {
  .issue-card > figure {
    display: none;
  }

  .our-solution aside {
    position: unset;
  }

  .our-solution main {
    align-items: center;
    margin-bottom: clamp(0.4rem, 11vw, 4rem);
  }

  .our-solution main h2 {
    text-align: center;
  }

  .our-solution main p {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .our-solution ul {
    grid-template: repeat(8, 1fr) / 1fr;
  } 
}