.nav-products-bar-container {
  flex: 1;
  color: var(--OxfordBlue);
}

.nav-products-bar-container > h3 {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: clamp(0.125rem, 8.5vw, 1.5rem);
  font-weight: 600;
  color: var(--OxfordBlue);
}

.nav-products-bar-container > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row nowrap;
  gap: 8px;
}

.nav-products-bar-container a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 8px;

  color: var(--OxfordBlue);
  font-size: clamp(0.1rem, 7vw, 1rem);
  text-decoration: none;

  transition: color 0.2s;
}

.nav-products-bar-container a > svg {
  width: 2rem;
  height: 2rem;

  transition: transform 0.2s;
}

.nav-products-bar-container a:hover {
  color: hsl(245, 50%, 20%);
}

.nav-products-bar-container a:hover >svg {
  transform: scale(1.2) translateY(-2px);
}