.custom-checkbox {
  position: relative;
  cursor: pointer;
  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  gap: 1rem;
  margin: 0.5rem 0.8rem;

  min-width: 8rem;
}

.custom-checkbox > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox > .checkmark {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 25px;
  width: 25px;
  background-color: white;

  border-radius: 5px;
  border: 1px solid #999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: var(--RISDBlue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  width: 5px;
  height: 10px;
  border: solid var(--Lavander);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}