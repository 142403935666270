:root {
  font-size: 16px;
  --RISDBlue: #355DF2;
  --Aquamarine: #52e1b1;
  --Teal: #0e7c7b;
  --Lavander: #ecebff;
  --OxfordBlue: #030026;
  --DogwoodRose: #d62264;

  font-family: 'Open Sans', sans-serif;
  font-size: clamp(0.1rem, 6vw, 1rem);
  

  
  /* Add the following code to hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
  -webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

p {
  font-size: clamp(0.1rem, 6vw, 1rem);
}

h1 { 
  font-size: clamp(0.3rem, 12vw, 5rem);
}

h2 {
  font-size: clamp(0.25rem, 11vw, 4rem);
}

h3 {
  font-size: clamp(0.2rem, 10vw, 3rem);
}

h4 {
  font-size: clamp(0.15rem, 9vw, 2rem);
}

h5 {
  font-size: clamp(0.1rem, 8vw, 1rem);
}

h6 {
  font-size: clamp(0.1rem, 7vw, 1rem);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
