.tag-card {

}

.tag-card > .wave-container {
  position: relative;
  width: 100%;
  height: 10vw;
  z-index: 40;
  top: 1px;

  overflow: hidden;

  object-fit: fill;


}

.tag-card > .tag-content {
  background-color: var(--OxfordBlue);
  padding: 2rem 10vw 5rem 10vw;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.tag-card h2 {
  color: white;
  font-weight: bold;
}

.tag-card p {
  color: white;
  opacity: .7;
}

.tag-card .custom-button {
  margin-top: clamp(0.1rem, 6vw, 1rem);
  padding: clamp(0.1rem, 6vw, 1rem) clamp(0.1rem, 6vw, 1rem);
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #355DF2;
  border-radius: clamp(0.1rem, 6vw, 1rem);
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.tag-card .custom-button:hover {
  background-color: #5976e0;
  color: #fff;
}

@media screen and (max-width: 350px) {
  .tag-card h2,
  .tag-card p {
    text-align: center;
  }


  .tag-card .custom-button {
    align-self: center;
  }
}