.team {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.team > header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 5em 3em 3em 3em;
}

.team > header > figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100%;
  width: clamp(1rem, 80vw, 12em);
  aspect-ratio: 1/1;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.team > header > figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team > header aside {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1em;
  padding: 1em 3em;
  flex: 1;
}

.team > header h3 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-style: italic;

  padding: 0;
  margin: 0;
}


.team-profiles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;

  width: 100%;
  gap: 2rem clamp(0.5rem, 2vw, 1.3em);
}

@media (max-width: 768px) {
  .team > header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 1em;
  }

  .team > header figure {
    width: 8em;
  }

  .team > header aside {
    padding: 1em;
  }

  .team > header h3,
  .team > header p {
    justify-content: center;
    text-align: center;
  }
}