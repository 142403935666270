.nav-tab-button { 
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 9999px;
  padding: 0.75rem 1.5rem;
  transition: color 0.3s;
  background-color: transparent;

  cursor: pointer;

  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 600;
}

.nav-tab-button-active {
  color: white;
}

.nav-tab-button-deactive {
  color: var(--Lavander);
}

.nav-tab-chevron {
  transition: transform 0.3s;
}

.nav-tab-chevron-active {
  transform: rotate(180deg);
}

.nav-tab-chevron-deactive {
  transform: rotate(0deg);
}