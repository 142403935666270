.App {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  

  
  /* Add the following code to hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
  -webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.App > header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
}

.App > main {
  min-height: 100vh;
}

.App > main > * {
  min-height: 100dvh;
}