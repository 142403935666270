#nav-menu {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  
}

#nav-menu > .bg {
  transition: opacity 0.3s;
}