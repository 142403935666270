.nav-nub {
  clip-path: polygon(0 0, 100% 0, 50% 50%, 0% 100%);
  position: absolute;
  left: 50%;
  top: 0;
  height: 10px;
  width: 10px;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  border-radius: 0 0 0.375rem 0;
  border: 1px solid var(--Lavander);
  background-color: #fff7;
}