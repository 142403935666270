.contact-page {
  background-color: #ECEBFF;
  padding-bottom: 2rem;
}

.contact-page .form-container {
  width: 80vw;
  margin: 0 10vw;
  margin-bottom: 5rem;
}

.contact-page .form-container h2{
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  color: #030026;
}

.contact-page form {
  display: grid;
  gap: 2rem;
}

.contact-page label {
  display: block;
  margin-bottom: 0.5rem;
}

.contact-page input,
.contact-page textarea {
  width: 100%;
  padding: 0.7rem;
  box-sizing: border-box;
  border-radius: 10px;
  border-color: #030026;
}

.contact-page button {
  display: inline-block;

  padding: 1vw 2vw;
  margin-top: 10px;
  border: none;
  border-radius: 10px;

  cursor: pointer;

  text-align: center;

  color: #fff;
  background-color: #355DF2 ;

  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-page button:hover {
  background-color: #5976e0 ;
}
