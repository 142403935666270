.profile-container aside h5,
.profile-container .time-span,
.profile-container .sub-role,
.profile-container .main-role p:last-of-type,
.profile-container main > main,
.profile-container footer {
  display: none;
}

.modal-container aside h5,
.modal-container .time-span,
.modal-container .sub-role,
.modal-container .role p:last-of-type,
.modal-container main > main,
.modal-container footer {
  display: unset;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.pm-container {
  width: 35vw;
  max-width: 15rem;
}

.pm-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pm-container > .profile {
  border-radius: calc(0.5rem + clamp(0.1rem, 1vw, 1rem));
  box-sizing: border-box;
  padding: clamp(0.1rem, 1vw, 1rem);
}

.pm-container figure {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 0.5rem;
}

.pm-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.pm-container aside {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.pm-container .time-span {
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.3rem;
}

.pm-container .social-links {
  font-size: clamp(0.5rem, 8vw, 1.5rem);
}

.pm-container .social-links * {
  font-size: inherit;
}

.pm-container main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.pm-container main > header {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.pm-container main > header h4 {
  width: 100%;
}

.pm-container main > main {
  flex: 1;

  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.3em;

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--Lavander) var(--OxfordBlue);
}

.pm-container h4 {
  width: 100%;
  font-weight: bold;
}

.pm-container h5 {
  width: 100%;
  font-weight: normal;
  font-style: italic;
}

.pm-container footer {
  grid-column: 1 / 3;
  grid-row: 3 / 4;

  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.pm-container footer > * {
  cursor: pointer;
}









.profile-container {
  transition: scale 0.1s ease-in-out;
}

.profile-container:hover {
  scale: 1.1;
}

.profile-container > .profile {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: clamp(0.2rem, 1vw, 1em);

  cursor: pointer;
}

.profile-container figure {
  order: 1;
}

.profile-container figure img {
  object-fit: cover;
}

.profile-container aside {
  order: 3;
  flex: 1;
  justify-content: flex-end;
  gap: clamp(0.1rem, 6vw, 1rem);
  text-align: center;
}

.profile-container main {
  order: 2;
  text-align: center;
}

.profile-container main > header {
  gap: clamp(0.2rem, 1vw, 1em);
  justify-content: space-between;
}

.profile-container h4 {
  font-size: clamp(0.5rem, 7vw, 1.5rem);
}

.profile-container h5 {
  font-size: clamp(0.3rem, 4vw, 1.2em);
}

.profile-container p {
  font-size: clamp(0.2rem, 4vw, 1em);
}

.profile-container .lead .social-links a {
  color: var(--Lavander);
}

.profile-container .non-lead .social-links a {
  color: var(--OxfordBlue);
}









.modal-container > .profile {
  position: fixed;
  top: 10%;
  left: 10%;

  width: 80%;
  height: 80%;
  padding: 1em;

  
  border-radius: 1.5em;
  overflow: hidden;

  /* color: var(--Lavander);
  background-color: var(--OxfordBlue); */

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 2rem;
  gap: 1em;
}

.modal-container figure {
  aspect-ratio: unset;
  width: 100%;
  height: 100%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  overflow: hidden;
}

.modal-container aside {
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  min-height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--Lavander) var(--OxfordBlue);

  padding: 0.5em
}

.modal-container .time-span {
  display: flex;
  width: 100%;
}

.modal-container .roles {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.3rem;
  width: 100%;
}

.modal-container .role {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal-container .role p:last-of-type {
  font-style: italic;
}

.modal-container .social-links {
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.modal-container .social-links a {
  color: var(--Lavander);
}

.modal-container h4 {
  font-size: clamp(0.7rem, 10vw, 2.5em);
}

.modal-container h5 {
  font-size: clamp(0.5rem, 8vw, 1.5em);
  font-weight: 700;
}

.modal-container p {
  font-size: clamp(0.2rem, 6vw, 1em);
}

.modal-container main {
  grid-column: 2 / 2;
  grid-row: 1 / 3;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal-container main > main {
  display: flex;
  font-size: clamp(0.2rem, 6vw, 1em);
}

.modal-container main > header {
  height: auto;
}

.modal-container footer {
  display: flex;
}

.modal-container footer * {
  font-size: inherit;
}

@media screen and (max-width: 768px) {
  .modal-container > .profile {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 2rem;
  }

  .modal-container figure {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .modal-container aside {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .modal-container main {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    overflow: hidden;
  }

  .modal-container footer {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .modal-container main > header h4 {
    text-align: left;
  }
}

@media screen and (max-width: 520px) {
  .modal-container > .profile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    border-radius: 1.5em 0 0 1.5em;

    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--Lavander) var(--OxfordBlue);
  }

  .modal-container > .profile::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 1rem;
  }

  .modal-container > .profile::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 1rem;
  }

  .modal-container figure {
    order: 1;
    width: 100%;
    height: auto;
    overflow: visible;

    flex: none;
  }

  .modal-container figure img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: top center;
    border-radius: 0.5rem;
  }

  .modal-container aside {
    order: 3;
    min-height: min-content;
    overflow: unset;
  }


  .modal-container main > header h4,
  .modal-container .timespan,
  .modal-container aside h5,
  .modal-container main *{
    text-align: center;
  }

  .modal-container .time-span,
  .modal-container .roles {
    align-items: center;
  }

  .modal-container .role {
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;

    text-align: center;
  }

  .modal-container main {
    order: 2;
    height: min-content;
    overflow: unset;
  }

  .modal-container main > main {
    overflow-y: visible;
    height: auto;
    flex: unset;
  }

  .modal-container footer {
    order: 4;
  }
}