.our-story {
  background-color: #ECEBFF ;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);

  padding: 5em 10vw;
  margin-bottom: 5em;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.our-story h2 {
  font-size: clamp(0.8rem, 8vw, 2.5rem);
  font-weight: bold;
  text-align: center;
}

.our-story p {
  line-height: 1.8;
  text-align: center;
  color: #4b4b4b;
}