.nav-products-menu-container {
  padding: 0 10vw;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.nav-products-menu-container > h3 {
  width: 100%;
  text-align: center;
  font-size: clamp(0.125rem, 8.5vw, 1.5rem);
  font-weight: 600;
  color: var(--Lavander);
}

.nav-products-menu-container > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row nowrap;
  gap: 10vw;
}

.nav-products-menu-container a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 8px;

  max-width: 5rem;

  color: var(--Lavander);
  text-decoration: none;

  transition: color 0.2s;
}

.nav-products-menu-container a > figure {
  aspect-ratio: 1/1;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  transition: transform 0.2s;
}

.nav-products-menu-container a > figure > svg {
  height: 100%;
  object-fit: cover;
}

.nav-products-menu-container a > p {
  font-size: clamp(0.1rem, 4vw, 1rem);
}

.nav-products-menu-container a:hover {
  color: white;
}

.nav-products-menu-container a:hover > figure {
  transform: scale(1.2) translateY(-2px);
}