.nav-about-menu-container {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  padding: 0 2rem;

  color: var(--Lavander);
}

.nav-about-menu-container h3 {
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: clamp(0.125rem, 8.5vw, 1.5rem);
  font-weight: 600;
  color: var(--Lavander);
}

.nav-about-menu-container > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  gap: 4px;

  width: 100%;
}

.nav-about-menu-container > div > div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.nav-about-menu-container a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  gap: 1.5rem;

  color: var(--Lavander);
  text-decoration: none;
  font-size: clamp(0.125rem, 8.5vw, 1.5rem);

  transition: color 0.2s;
}

.nav-about-menu-container a:hover {
  color: white;
}